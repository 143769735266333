import React, { FC, useEffect, useState, useRef, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { format } from 'date-fns';

// Components:
import { Button, ButtonHollow, Dialog, LoadingSpinner } from 'components';
import Dependencies from './Partials/Dependencies';
import DeviceComponents from './DeviceComponents';
import DeviceModel from './Partials/Model';
import General from './Partials/General';
import Media from './Partials/Media';
import SubNav from './SubNav';
import Translations from './Partials/Translations';

// Styles:
import './style.css';
import 'react-table/react-table.css';

// Types:
import Device, { Category, Dependency, Description, Energy, EnergySource, Model, Subtype, Translation, Type, DeviceComponent } from 'types/Device';
import Component from 'types/Component';
import Language from 'types/Language';

import { Category as FullCategory } from 'types/Category';

// Utils:
import { get, post } from 'utils/AJAX';

// Babylon:
import PreviewBabylonScene from 'components/babylon/PreviewBabylonScene';

import DeviceNode from 'components/babylon/node/DeviceNode';
import DevicePreview from './Partials/DevicePreview';
import { DeviceView } from '../../types/DeviceView';
import { DxfCoordinates } from '../../types/DxfCoordinates';
import DXF from './Partials/DXF';
import authContext from '../../context/auth-context';

// =========================================================
interface Props extends RouteComponentProps {}
// =========================================================

const DeviceEdit: FC<Props> = ({ history }) => {
  const { userRights } = useContext(authContext);
  const id = window.location.pathname.split('/')[2];
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<FullCategory[]>([]);
  const [category, setCategory] = useState<Category>({ id: '' });
  const [code, setCode] = useState('');
  const [components, setComponents] = useState<DeviceComponent[]>([]);
  const [componentResults, setComponentResults] = useState<Component[]>([]);
  const [componentSearchStarted, setComponentSearchStarted] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [syncError, setSyncError] = useState(false);
  const [showSyncDialog, setShowSyncDialog] = useState(false);

  const [timestamps, setTimestamps] = useState({ updated: 0, sync: 0, updatedSync: 0, deleted: 0 });

  const [dependency, setDependency] = useState<Dependency>({
    expandableRight: false,
    expandableLeft: false,
    coverEnlargement: false,
    coverEnlargementSize: 0,
    colorBlend: false,
    equipmentCombinations: []
  });
  const [description, setDescription] = useState<Description>({
    norm: '',
    name700: '',
    name850: ''
  });
  const [device, setDevice] = useState<Device>(null);
  const [dxfCoordinates, setDxfCoordinates] = useState<DxfCoordinates>({
    depth: 0,
    x: 0,
    y: 0
  });

  const [energy, setEnergy] = useState<Energy>(() => {
    const source: EnergySource = EnergySource.Neutral;
    return {
      source,
      elektro: 0,
      gas: 0,
      steam: 0,
      voltage: 0,
      electroConditions: ''
    };
  });
  const [error, setError] = useState(false);
  const [filterComponent, setFilterComponent] = useState('');
  const [languages, setLanguages] = useState([]);
  const [model, setModel] = useState<Model>({
    width: 0,
    widthSubstructure: 0,
    height: 0,
    depths: [0],
    marineMeister: false,
    masterline: false,
    modular: false,
    spaceCombi: false,
    flexiChef: false,
    buildBaseCorpus: false,
    extendCover: false,
    extendCoverMoveBack: false,
    extendCoverSize: 0,
    modularBorderIncluded: false
  });
  const [moneyCode, setMoneyCode] = useState('');
  const [newEquipment, setNewEquipment] = useState<Subtype>(Subtype['0']);
  const [position, setPosition] = useState(0);
  const [price, setPrice] = useState(0);
  const [priceM, setPriceM] = useState(0);
  const [priceM2, setPriceM2] = useState(0);
  const [deviceSubtype, setDeviceSubtype] = useState<Subtype>(Subtype['0']);
  const [style, setStyle] = useState('');
  const [success, setSuccess] = useState(false);
  const [translations, setTranslations] = useState<Translation>(null);
  const [type, setType] = useState<Type>(Type.Device);
  const [view, setView] = useState<DeviceView>('general');
  const sceneRef = useRef<PreviewBabylonScene>(null);

  // =================================

  const saveChanges = async () => {
    setLoading(true);
    console.log('DEP: ', dependency);

    const saveData =
      id === 'new'
        ? {
            category: type === Type.Equipment || type === Type.Option || category.id === 'noCat' ? null : category,
            code,
            type: Type[type],
            subtype: type === Type.Equipment || type === Type.Option ? deviceSubtype : null,
            description,
            translations,
            moneyCode,
            model,
            energy: { ...energy, source: EnergySource[energy.source] },
            position,
            price,
            priceM,
            priceM2,
            dependency,
            components,
            style
          }
        : {
            id,
            category: type === Type.Equipment || type === Type.Option || category.id === 'noCat' ? null : category,
            code,
            type,
            subtype: type === Type.Equipment || type === Type.Option ? deviceSubtype : null,
            description,
            translations,
            model,
            moneyCode,
            energy,
            position,
            price,
            priceM,
            priceM2,
            dependency,
            components,
            style
          };

    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/save`, {
      data: saveData
    });
    if (data) {
      if (id === 'new') {
        history.push(`/devices/${data.id}`);
      }
      setTimestamps({
        updated: data?.updated,
        sync: data?.sync,
        updatedSync: data?.updatedSync,
        deleted: data?.deleted
      });
    }
    if (error) {
      console.log(error);
    }
    setLoading(false);
  };

  // =================================

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/category/search`, {
        data: {
          rows: 100,
          offset: 0,
          query: '',
          sort: {
            field: 'created',
            order: 'ASC'
          }
        }
      });
      if (data) {
        setCategories([
          ...data,
          {
            id: 'noCat',
            children: [],
            translations: {
              vdrqR: { name: 'Keine' },
              '043r1': { name: 'None' }
            }
          }
        ]);
        if (id === 'new') {
          setCategory({ id: 'noCat' });
        }
      }
      if (error) {
        console.log(error);
      }
      setLoading(false);
    };

    const fetchDevice = async () => {
      setLoading(true);

      const { data, error } = await get(`${process.env.REACT_APP_API_URL}/device/get/${id}`);

      if (data) {
        setCategory(data.category ? data.category : { id: 'None' });
        setComponents(data.components || []);
        setDependency({
          ...data.dependency,
          equipmentCombinations: data.dependency.equipmentCombinations.map((dep: Subtype) => Subtype[dep])
        });
        setDeviceSubtype(data.subtype);
        setDescription(data.description);
        setDevice(data);
        setCode(data.code);
        setEnergy(data.energy);
        setModel(
          data.model && typeof data.model.extendCoverMoveBack === 'boolean' && typeof data.model.extendCoverSize === 'number'
            ? { ...model, ...data.model }
            : data.model && typeof data.model.extendCoverMoveBack === 'boolean'
            ? { ...model, ...data.model, extendCoverSize: 0 }
            : data.model && typeof data.model.extendCoverSize === 'number'
            ? { ...model, ...data.model, extendCoverMoveBack: false }
            : {
                ...model,
                ...data.model,
                extendCoverMoveBack: false,
                extendCoverSize: 0
              }
        );
        setMoneyCode(data.moneyCode ? data.moneyCode : '');
        setPosition(data.position ? data.position : 0);
        setPrice(data.price);
        setPriceM(data.priceM ? data.priceM : 0);
        setPriceM2(data.priceM2 ? data.priceM2 : 0);
        setStyle(data.style ? data.style : '');
        setTimestamps({
          updated: data?.updated,
          sync: data?.sync,
          updatedSync: data?.updatedSync,
          deleted: data?.deleted
        });
        setTranslations(data.translations);
        setType(data.type === 'Equipment' ? 1 : data.type === 'Device' ? 0 : 2);
        setPreviewDepth(data.description.name700 && data.description.name700.length > 0 ? 700 : 850);
      }
      if (error) {
        console.log(error);
      }
      setLoading(false);
    };

    const fetchLanguages = async () => {
      setLoading(true);
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/language/search`, {
        data: {
          rows: 999,
          offset: 0,
          query: '',
          sort: {
            field: 'name',
            order: 'ASC'
          }
        }
      });
      if (data) {
        setLanguages(data);
        // Initialize Translations for new devices
        if (id === 'new') {
          const ids = data.map((l: Language) => l.id);
          // @ts-ignore
          const existingTranslations: Translation = {};
          ids.forEach((id: string) => {
            Object.assign(existingTranslations, {
              [id]: { name: '', description: '' }
            });
          });
          setTranslations(existingTranslations);
        }
      }
      if (error) {
        console.log(error);
      }
      setLoading(false);
    };

    fetchCategories();
    fetchLanguages();
    if (id && id !== 'new') {
      fetchDevice();
    }
  }, [id]);

  const [previewObject, setPreviewObject] = useState<DeviceNode>(null);
  const [previewDepth, setPreviewDepth] = useState<number>(850);
  const refreshPreview = (depth?: number, extendCover?: boolean, buildBaseCorpus?: boolean) => {
    if (previewObject && !previewObject.isDisposed()) {
      previewObject.dispose();
    }
    const deviceNode = new DeviceNode({
      id: id,
      width: model.width,
      left: true,
      right: true,
      depth: depth || previewDepth,
      bottom: 'Feet',
      device: device
    });

    deviceNode.position.x = -model.width / 20;
    deviceNode.position.z = -previewDepth / 20;

    if (sceneRef && sceneRef.current) sceneRef.current.setPreview(deviceNode);

    setPreviewObject(deviceNode);
  };

  const deleteDevice = async () => {
    setDeleting(true);
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/delete`, {
      data: { id }
    });
    if (data) {
      setDeleting(false);

      history.push('/devices');
    } else if (error) {
      setDeleteError(true);
    }
    setDeleting(false);
  };

  const syncDevice = async () => {
    setSyncing(true);
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/sync`, {
      data: { id }
    });
    if (data) {
      setTimestamps({
        updated: data?.updated,
        sync: data?.sync,
        updatedSync: data?.updatedSync,
        deleted: data?.deleted
      });
      setSyncing(false);
      setShowSyncDialog(false);
    } else if (error) {
      setSyncError(true);
    }
    setSyncing(false);
  };

  // ======================================================================================================

  if (loading) {
    return <LoadingSpinner />;
  }

  if (device || id === 'new') {
    return (
      <div className="Device-Wrapper">
        <div>
          <h1>{id === 'new' ? 'Create Device' : 'Edit Device'}</h1>
        </div>
        <p style={{ height: '15px', fontSize: '0.75rem', margin: '.5rem auto' }}>{deleteError ? 'Could not delete device.' : ''}</p>

        <SubNav setView={setView} view={view} />
        <div className="Device-ControlsWrapper">
          {view === 'general' && (
            <General
              categories={categories}
              category={category}
              code={code}
              description={description}
              deviceSubtype={deviceSubtype}
              moneyCode={moneyCode}
              position={position}
              price={price}
              priceM={priceM}
              priceM2={priceM2}
              setCategory={setCategory}
              setCode={setCode}
              setDescription={setDescription}
              setDeviceSubtype={setDeviceSubtype}
              setMoneyCode={setMoneyCode}
              setPosition={setPosition}
              setPrice={setPrice}
              setPriceM={setPriceM}
              setPriceM2={setPriceM2}
              setStyle={setStyle}
              setType={setType}
              style={style}
              type={type}
            />
          )}
          {view === 'translations' && <Translations languages={languages} translations={translations} setTranslations={setTranslations} />}

          {view === 'model' && <DeviceModel energy={energy} setEnergy={setEnergy} model={model} setModel={setModel} />}

          {view === 'dependencies' && (
            <Dependencies dependency={dependency} setDependency={setDependency} newEquipment={newEquipment} setNewEquipment={setNewEquipment} />
          )}
          {view === 'media' && (
            <Media
              components={components}
              device={device}
              id={id}
              model={model}
              previewDepth={previewDepth}
              previewObject={previewObject}
              sceneRef={sceneRef}
              setError={setError}
              setModel={setModel}
              setPreviewObject={setPreviewObject}
              setSuccess={setSuccess}
              type={type}
            />
          )}

          {view === 'components' && device && device.id !== 'new' && (
            <DeviceComponents
              components={components}
              componentResults={componentResults}
              componentSearchStarted={componentSearchStarted}
              filterComponent={filterComponent}
              setComponents={setComponents}
              setComponentResults={setComponentResults}
              setComponentSearchStarted={setComponentSearchStarted}
              setFilterComponent={setFilterComponent}
              updateScene={() => refreshPreview()}
            />
          )}

          {(view === 'media' || view === 'components') && device && device.id !== 'new' && (
            <DevicePreview
              components={components}
              description={description}
              device={device}
              id={id}
              model={model}
              previewDepth={previewDepth}
              previewObject={previewObject}
              sceneRef={sceneRef}
              setPreviewObject={setPreviewObject}
              setPreviewDepth={setPreviewDepth}
              type={type}
            />
          )}

          {view === 'dxf' && <DXF coordinates={dxfCoordinates} setCoordinates={setDxfCoordinates} />}

          <div className="Device-ButtonContainer">
            <ButtonHollow onClick={() => history.push('/devices')}>Cancel</ButtonHollow>
            <Button
              btnType="first"
              disabled={
                id !== 'new' &&
                (device.model.masterline
                  ? Array.isArray(userRights) && !userRights.includes('EditMasterline')
                  : device.model.modular || device.model.spaceCombi || device.model.flexiChef
                  ? Array.isArray(userRights) && !userRights.includes('EditModular')
                  : false)
              }
              onClick={saveChanges}
            >
              Save
            </Button>
            <br />

            {loading && <LoadingSpinner />}
          </div>

          <div style={{ marginTop: '1rem', textAlign: 'center' }}>
            <Button btnType="delete" disabled={!id || (id && id === 'new')} onClick={() => setShowDeleteDialog(true)}>
              {!deleting ? 'Delete Device' : <LoadingSpinner size="15px" margin="auto" />}
            </Button>
          </div>
          <div style={{ marginTop: '1rem', textAlign: 'center' }}>
            {timestamps.updated > 0 && <p>Last updated: {format(new Date(timestamps.updated), 'hh:mm - dd/MM/yy')}</p>}
            {timestamps.sync > 0 && <p>Set to synced at: {format(new Date(timestamps.sync), 'hh:mm - dd/MM/yy')}</p>}
            {timestamps.updatedSync > 0 && <p>Last Sync: {format(new Date(timestamps.updatedSync), 'hh:mm - dd/MM/yy')}</p>}
            {timestamps.deleted > 0 && <p>Deleted at: {format(new Date(timestamps.deleted), 'hh:mm - dd/MM/yy')}</p>}
          </div>
          <div style={{ marginTop: '1rem', textAlign: 'center' }}>
            <Button btnType="first" disabled={!id || (id && id === 'new')} onClick={() => setShowSyncDialog(true)}>
              {!syncing ? 'Synchronize Device' : <LoadingSpinner size="15px" margin="auto" />}
            </Button>
          </div>
        </div>
        <Dialog
          heading="Confirm Deletion"
          open={showDeleteDialog}
          onAbort={() => {
            setShowDeleteDialog(false);
          }}
          onConfirm={() => {
            deleteDevice();
          }}
          text="Are you sure you want to delete?"
        />
        <Dialog
          heading="Confirm"
          open={showSyncDialog}
          onAbort={() => {
            setShowSyncDialog(false);
          }}
          onConfirm={() => {
            syncDevice();
          }}
          text="Are you sure you want to synchronize?"
        />
      </div>
    );
  } else return <div>Device not found.</div>;
};

export default DeviceEdit;
