import * as BABYLON from 'babylonjs';
import BasicUtils from '../util/BasicUtils';
import MarkUtils from '../util/MarkUtils';
import HighPerformanceQueue from '../helper/HighPerformanceQueue';
import LabelUtils, { Orientation } from '../util/LabelUtils';
import { OptionNode } from './OptionNode';
import LoaderUtils from '../util/LoaderUtils';
import { Device } from 'types/Device';

type Config = {
  model: ConfigModel;
  output?: ConfigOutput;
};

type ConfigModel = {
  node?: BABYLON.TransformNode;
  base?: BABYLON.TransformNode;
  main?: BABYLON.TransformNode;
  device?: Device;
  options: Map<number, OptionNode>;
};

type ConfigOutput = {
  node?: BABYLON.TransformNode;
  mark?: BABYLON.TransformNode;
  markFull?: BABYLON.TransformNode;
  label?: BABYLON.TransformNode;
};

export enum PropertyType {
  /**
   * boolean
   */
  Left,
  /**
   * boolean
   */
  Right,
  /**
   * boolean
   */
  MergeCorpusLeft,
  /**
   * boolean
   */
  MergeCorpusRight,
  /**
   * string
   */
  Border,
  /**
   * string
   */
  Handle,
  /**
   * boolean
   */
  HandleLeft,
  /**
   * boolean
   */
  HandleRight,
  /**
   * String
   */
  Bottom,
  /**
   * Number
   */
  BottomHeight,
  /**
   * Number
   */
  Width,
  /**
   * Number
   */
  Depth,

  /**
   * boolean
   */
  Mark,
  /**
   * boolean
   */
  MarkFull,
  /**
   * boolean
   */
  Label,
  /**
   * boolean
   */
  Rotate
}

enum ApplyType {
  Position,
  Merge,
  Height,
  Width,
  Depth,
  Rotate,
  Handle
}

type DeviceNodeDefaultSettings = {
  shadowGenerators?: BABYLON.ShadowGenerator[];
  mirrors?: BABYLON.MirrorTexture[];
  modelNode: BABYLON.TransformNode;
  scene?: BABYLON.Scene;
};

export type DeviceNodeSettings = {
  id: string;
  left?: boolean;
  right?: boolean;
  border?: string;
  handle?: string;
  bottom?: 'BaseMKN' | 'Base' | 'Feet';
  bottomHeight?: number;
  width?: number;
  depth?: number;
  device?: Device;
};

export default class DeviceNode extends BABYLON.TransformNode {
  public static defaultSettings: DeviceNodeDefaultSettings = {
    shadowGenerators: [],
    mirrors: [],
    modelNode: null
  };

  private config: Config = {
    model: {
      node: null,
      base: null,
      options: new Map<number, OptionNode>()
    },
    output: {
      node: null,
      mark: null,
      markFull: null,
      label: null
    }
  };

  private deviceId: string;
  private settings = new Map<PropertyType, string | boolean | number>();

  private _mainSplit = false;

  constructor(device: DeviceNodeSettings) {
    super('Device', DeviceNode.defaultSettings.scene, undefined);
    this.config.model.node = new BABYLON.TransformNode('model.' + this.uniqueId, DeviceNode.defaultSettings.scene);
    this.config.model.node.parent = DeviceNode.defaultSettings.modelNode;
    this.config.model.node.setEnabled(false);
    this.config.output.node = new BABYLON.TransformNode('output', DeviceNode.defaultSettings.scene);
    this.config.output.node.parent = this;

    {
      this.deviceId = device.id;
      this.set(PropertyType.Left, device.left || false);
      this.set(PropertyType.Right, device.right || false);
      this.set(PropertyType.Border, device.border || 'BorderAngular');
      this.set(PropertyType.Handle, device.handle || null);
      this.set(PropertyType.Bottom, device.bottom || 'Feet');
      this.set(PropertyType.BottomHeight, device.bottomHeight || 20);
      this.set(PropertyType.Width, device.width || 600);
      this.set(PropertyType.Depth, device.depth || 850);

      this.config.model.device = device.device;

      // Add Base Corpus to Node
      LoaderUtils.loadBase(this, node => {
        node.bake();
      });
      // Load Main Model
      const toLoad = (device.device.components ? device.device.components.length : 0) + 1;
      let loaded = 0;
      LoaderUtils.loadDevice(
        this,
        () => {
          if (++loaded >= toLoad) this.bake();
        },
        () => {
          if (++loaded >= toLoad) this.bake();
        }
      );
      // Attach all Components
      if (device.device.components) {
        for (let i = 0; i < device.device.components.length; i++) {
          const component = device.device.components[i];
          LoaderUtils.loadComponent(
            this,
            component,
            () => {
              if (++loaded >= toLoad) this.bake();
            },
            () => {
              if (++loaded >= toLoad) this.bake();
            }
          );
        }
      }
    }
  }

  public addBase(base: BABYLON.TransformNode): void {
    if (this.config.model.device.model.flexiChef || this.config.model.device.model.spaceCombi) {
      base.dispose();
      return;
    }
    base.parent = this.config.model.node;
    this.config.model.base = base;

    this.checkColorBlend();

    this.applySettings(ApplyType.Depth);
    this.applySettings(ApplyType.Width);
    this.applySettings(ApplyType.Height);

    this.applySettings(ApplyType.Handle);
  }

  public setMain(main: BABYLON.TransformNode): void {
    main.parent = this.config.model.node;
    this.config.model.main = main;
    // Apply Height
    if (this.config.model.device.model.flexiChef || this.config.model.device.model.spaceCombi) main.position.y = 0;
    else main.position.y = this.settings.get(PropertyType.BottomHeight) as number;

    if (BasicUtils.findFirstChild('700', main) || BasicUtils.findFirstChild('850', main)) this._mainSplit = true;

    this.applySettings(ApplyType.Depth);

    // Modular adjustment
    if (this.config.model.device && this.config.model.device.model.modular) {
      if (!this.config.model.device.model.modularBorderIncluded) {
        main.scaling.x = (this.config.model.device.model.width - 30) / this.config.model.device.model.width;
        main.position.x = 3;
      }
      this.set(PropertyType.Border, 'BorderTrench');
    }
  }

  public updateMain() {
    if (this.config.model.main) this.config.model.main.dispose();
    this.config.model.main = null;
    LoaderUtils.loadDevice(
      this,
      () => {
        this.bake();
      },
      () => {
        this.bake();
      }
    );
  }

  public addOption(option: OptionNode, bake?: boolean): OptionNode {
    // Set Parent
    option.parent = this.config.model.node;
    // Apply Position
    if (option.getDeviceComponent().position)
      option.getModel().position = new BABYLON.Vector3(
        option.getDeviceComponent().position.x,
        option.getDeviceComponent().position.y,
        option.getDeviceComponent().position.z
      );
    if (option.getDeviceComponent().scaling)
      option.getModel().scaling = new BABYLON.Vector3(
        option.getDeviceComponent().scaling.x,
        option.getDeviceComponent().scaling.y,
        option.getDeviceComponent().scaling.z
      );
    // Prepare Type
    option.setContainer(this);
    option.prepareType();
    // Apply Height
    if (!this.config.model.device.model.flexiChef) {
      option.position.y = this.settings.get(PropertyType.BottomHeight) as number;
    }
    // Apply Depth
    if (!option.getDeviceComponent().fixed) {
      switch (this.settings.get(PropertyType.Depth) as number) {
        case 700:
          option.position.z = 15;
          break;
        case 850:
          option.position.z = 0;
          break;
      }
    }
    // Add to Map
    this.config.model.options.set(option.uniqueId, option);

    if (bake) this.bake();

    this.checkColorBlend();

    return option;
  }

  public removeOption(option: BABYLON.TransformNode | string | number): void {
    if (option instanceof BABYLON.TransformNode) {
      // Node
      this.config.model.options.delete(option.uniqueId);
      if (!option.isDisposed()) option.dispose();
    } else if (typeof option === 'number') {
      // Number
      const obj = this.config.model.options.get(option);
      if (obj) {
        this.config.model.options.delete(option);
        if (!obj.isDisposed()) obj.dispose();
      }
    } else {
      // Name
      let obj: BABYLON.TransformNode;
      this.config.model.options.forEach(element => {
        if (element.name === (option as string)) {
          obj = element;
        }
      });
      if (obj) {
        this.config.model.options.delete(obj.uniqueId);
        if (!obj.isDisposed()) obj.dispose();
      }
    }

    this.checkColorBlend();
  }

  public containsOption(componentId: string) {
    const options = this.config.model.options.values();
    let option = null;
    while (typeof (option = options.next().value) !== 'undefined') {
      if (option.getDeviceComponent().component.id === componentId) return true;
    }
    return false;
  }

  private checkColorBlend() {
    // Not here
  }

  private applySettings(type: ApplyType) {
    const base = this.config.model.base;

    switch (type) {
      case ApplyType.Position:
        {
          if (!base) return;
          const targetWidth = this.settings.get(PropertyType.Width) as number;
          // mm to cm
          const width = targetWidth / 10;
          // cm to scaling
          const scaling = width / 10;

          const left = this.settings.get(PropertyType.Left);
          const right = this.settings.get(PropertyType.Right);

          // Panel Settings
          {
            const node = BasicUtils.findFirstChild('Panel', base);
            const center = BasicUtils.findFirstChild('Center', node);
            if (left && right) {
              center.scaling.x = Math.max(0, scaling - 2);
              center.position.x = 10;
            } else if (left) {
              center.scaling.x = Math.max(0, scaling - 1);
              center.position.x = 10;
            } else if (right) {
              center.scaling.x = Math.max(0, scaling - 1);
              center.position.x = 0;
            } else {
              center.scaling.x = Math.max(0, scaling);
              center.position.x = 0;
            }
          }
          {
            const node = BasicUtils.findFirstChild('PanelBack', base);
            const center = BasicUtils.findFirstChild('Center', node);
            if (left && right) {
              center.scaling.x = Math.max(0, scaling - 2);
              center.position.x = 10;
            } else if (left) {
              center.scaling.x = Math.max(0, scaling - 1);
              center.position.x = 10;
            } else if (right) {
              center.scaling.x = Math.max(0, scaling - 1);
              center.position.x = 0;
            } else {
              center.scaling.x = Math.max(0, scaling);
              center.position.x = 0;
            }
          }

          // Bottom Part
          // PedestrialCover Settings
          {
            const node = BasicUtils.findFirstChild('PedestrialCover', base);
            const center = BasicUtils.findFirstChild('Center', node);
            if (left && right) {
              center.scaling.x = Math.max(0, scaling - 2);
              center.position.x = 10;
            } else if (left) {
              center.scaling.x = Math.max(0, scaling - 1);
              center.position.x = 10;
            } else if (right) {
              center.scaling.x = Math.max(0, scaling - 1);
              center.position.x = 0;
            } else {
              center.scaling.x = Math.max(0, scaling);
              center.position.x = 0;
            }
          }
        }
        break;

      case ApplyType.Merge:
        {
          if (!base) return;
          const targetWidth = this.settings.get(PropertyType.Width) as number;
          // mm to cm
          const width = targetWidth / 10;
          // cm to scaling
          const scaling = width / 10;

          const mergeCorpusLeft = this.settings.get(PropertyType.MergeCorpusLeft);
          const mergeCorpusRight = this.settings.get(PropertyType.MergeCorpusRight);
          // Corpus Settings
          {
            const node = BasicUtils.findFirstChild('Corpus', base);
            const center = BasicUtils.findFirstChild('Center', node);
            if (mergeCorpusLeft && mergeCorpusRight) {
              center.scaling.x = Math.max(0, scaling);
              center.position.x = 0;
            } else if (mergeCorpusLeft) {
              center.scaling.x = Math.max(0, scaling - 1);
              center.position.x = 0;
            } else if (mergeCorpusRight) {
              center.scaling.x = Math.max(0, scaling - 1);
              center.position.x = 10;
            }
          }
        }
        break;

      case ApplyType.Height:
        {
          if (!base) return;
          const height = this.settings.get(PropertyType.BottomHeight) as number;

          {
            const top = BasicUtils.findFirstChild('Top', base);
            const bottom = BasicUtils.findFirstChild('Bottom', base);
            top.position.y = height;
            bottom.scaling.y = height / 20;
          }

          // All options
          const options = this.config.model.options.values();
          let option = null;
          while (typeof (option = options.next().value) !== 'undefined') {
            option.position.y = height;
          }
        }
        break;

      case ApplyType.Depth:
        this.applySettings(ApplyType.Rotate);
        if (!base) return;
        const targetDepth = this.settings.get(PropertyType.Depth) as number;
        // mm to cm
        const depth = targetDepth / 10;
        // Panel settings
        {
          const backPanel = BasicUtils.findFirstChild('PanelBack', base);
          if (backPanel) backPanel.position.z = -depth + 5.2;
        }
        switch (targetDepth) {
          case 700:
            {
              const children = base.getChildTransformNodes();
              for (let i = 0; i < children.length; i++) {
                const child = children[i];
                if (child.name === 'Flex') {
                  child.position.z = 15;
                  // const flexParts = child.getChildMeshes();
                  // for (let j = 0; j < flexParts.length; j++) {
                  //   const flexPart = flexParts[j];
                  //   flexPart.position.z = 15;
                  // }
                }
              }

              // All options
              const options = this.config.model.options.values();
              let option = null;
              while (typeof (option = options.next().value) !== 'undefined') {
                if (option instanceof OptionNode) {
                  if (!option.getDeviceComponent().fixed) option.position.z = 15;
                  option.prepareTypeDepthChange();
                }
              }
              // Move Main
              if (this.config.model.main) this.config.model.main.position.z = 0;
            }
            break;
          case 850:
            {
              const children = base.getChildTransformNodes();
              for (let i = 0; i < children.length; i++) {
                const child = children[i];
                if (child.name === 'Flex') {
                  child.position.z = 0;
                  // const flexParts = child.getChildMeshes();
                  // for (let j = 0; j < flexParts.length; j++) {
                  //   const flexPart = flexParts[j];
                  //   flexPart.position.z = 0;
                  // }
                }
              }

              // All options
              const options = this.config.model.options.values();
              let option = null;
              while (typeof (option = options.next().value) !== 'undefined') {
                if (option instanceof OptionNode) {
                  if (!option.getDeviceComponent().fixed) option.position.z = 0;
                  option.prepareTypeDepthChange();
                }
              }

              // Move Main & Cover Up
              if (this.config.model.device && this.config.model.device.model.extendCover) {
                const extendCoverModelFront = BasicUtils.findFirstChild('CoverSplit:Front', this.config.model.base);
                const extendCoverModelBack = BasicUtils.findFirstChild('CoverSplit:Back', this.config.model.base);
                const extendCoverSize = this.config.model.device.model.extendCoverSize;
                const scaleFront = Math.max(0, Math.min(15, extendCoverSize / 100));
                const scaleBack = Math.max(0, Math.min(15, (150 - extendCoverSize) / 100));
                extendCoverModelFront.getChildMeshes().forEach(mesh => {
                  mesh.scaling.z = scaleFront;
                });
                extendCoverModelBack.getChildMeshes().forEach(mesh => {
                  mesh.scaling.z = scaleBack;
                });
                console.log('####', extendCoverSize, this.config.model.main, -15 + extendCoverSize / 10);
                if (this.config.model.main) this.config.model.main.position.z = -15 + extendCoverSize / 10;
              } else {
                if (this.config.model.main) this.config.model.main.position.z = 0;
              }
            }
            break;
        }
        break;

      case ApplyType.Width:
        {
          this.applySettings(ApplyType.Rotate);
          if (!base) return;
          const targetWidth = this.settings.get(PropertyType.Width) as number;
          const targetDepth = this.settings.get(PropertyType.Depth) as number;
          // mm to cm
          const width = targetWidth / 10;
          const depth = targetDepth / 10;
          // cm to scaling
          const scaling = width / 10;

          // Top Part
          // Border Settings
          const borders = ['BorderAngular', 'BorderAngular_chamfer', 'BorderAngular_upturn', 'BorderAngular_weld', 'BorderRound', 'BorderTrench'];
          for (let i = 0; i < borders.length; i++) {
            const border = borders[i];
            const node = BasicUtils.findFirstChild(border, base);
            if ('BorderTrench' === border) BasicUtils.findFirstChild('Center', node).scaling.x = Math.max(0, scaling - 0.3);
            else BasicUtils.findFirstChild('Center', node).scaling.x = Math.max(0, scaling);
            // BasicUtils.findFirstChild('Center', node).scaling.x = Math.max(0, scaling);
            const left = BasicUtils.findFirstChild('Left', node);
            const right = BasicUtils.clone(left, 'Right');
            right.position.x = width;
            right.scaling.x = -1;
          }
          // Handle Settings
          const handles = ['Handle20x40', 'Handle40x80', 'HandleRound'];
          for (let i = 0; i < handles.length; i++) {
            const node = BasicUtils.findFirstChild(handles[i], base);
            BasicUtils.findFirstChild('Center', node).scaling.x = Math.max(0, scaling);
            const left = BasicUtils.findFirstChild('Left', node);
            const right = BasicUtils.clone(left, 'Right');
            right.position.x = width;
            right.scaling.x = -1;
          }
          // HandleMount Settings
          {
            const node = BasicUtils.findFirstChild('HandleMount', base);
            const left = BasicUtils.findFirstChild('Left', node);
            const right = BasicUtils.clone(left, 'Right');
            right.position.x = width;
            right.scaling.x = -1;
          }
          // Corpus Settings
          {
            const node = BasicUtils.findFirstChild('Corpus', base);
            BasicUtils.findFirstChild('Center', node).scaling.x = Math.max(0, scaling - 2);
            const left = BasicUtils.findFirstChild('Left', node);
            const right = BasicUtils.clone(left, 'Right');
            right.position.x = width;
            right.scaling.x = -1;
          }
          // CorpusSideCover Settings
          {
            const node = BasicUtils.findFirstChild('CorpusSideCover', base);
            const left = BasicUtils.findFirstChild('Left', node);
            const right = BasicUtils.clone(left, 'Right');
            right.position.x = width;
            right.scaling.x = -1;
          }
          // Cover Settings
          {
            const node = BasicUtils.findFirstChild('Cover', base);
            node.scaling.x = scaling;
          }
          // CoverSplit Settings
          {
            const node = BasicUtils.findFirstChild('CoverSplit', base);
            node.scaling.x = scaling;
          }
          // Panel Settings
          {
            const node = BasicUtils.findFirstChild('Panel', base);
            BasicUtils.findFirstChild('Center', node).scaling.x = Math.max(0, scaling - 2);
            const left = BasicUtils.findFirstChild('Left', node);
            const right = BasicUtils.clone(left, 'Right');
            right.position.x = width;
            right.scaling.x = -1;

            const backPanel = BasicUtils.clone(node, 'PanelBack');
            backPanel.scaling.z = -1;
            backPanel.position.z = -depth + 5.2;
          }
          // UpturnBack Settings
          {
            const node = BasicUtils.findFirstChild('UpturnBack', base);
            const left = BasicUtils.findFirstChild('Left', node);
            const right = BasicUtils.clone(left, 'Right');
            right.position.x = width;
            right.scaling.x = -1;
            const center = BasicUtils.findFirstChild('Center', node);
            center.scaling.x = Math.max(0, scaling);
          }

          // Bottom Part
          // PedestrialCover Settings
          {
            const node = BasicUtils.findFirstChild('PedestrialCover', base);
            BasicUtils.findFirstChild('Center', node).scaling.x = Math.max(0, scaling - 2);
            const left = BasicUtils.findFirstChild('Left', node);
            const right = BasicUtils.clone(left, 'Right');
            right.position.x = width;
            right.scaling.x = -1;
          }
          // Feet Settings
          {
            const node = BasicUtils.findFirstChild('Feet', base);
            const left = BasicUtils.findFirstChild('Left', node);
            const right = BasicUtils.clone(left, 'Right');
            right.position.x = width;
            right.scaling.x = -1;
          }

          this.applySettings(ApplyType.Position);
          this.applySettings(ApplyType.Merge);
        }
        break;

      case ApplyType.Rotate:
        if (this.settings.get(PropertyType.Rotate)) {
          this.config.output.node.position = new BABYLON.Vector3(
            (this.get(PropertyType.Width) as number) / 10,
            0,
            -(this.get(PropertyType.Depth) as number) / 10
          );
          this.config.output.node.rotation = new BABYLON.Vector3(0, Math.PI, 0);
        } else {
          this.config.output.node.position = BABYLON.Vector3.Zero();
          this.config.output.node.rotation = BABYLON.Vector3.Zero();
        }
        break;

      case ApplyType.Handle:
        let handle;
        if (this.config.model.base && (handle = this.settings.get(PropertyType.Handle)) && typeof handle === 'string') {
          let handleSplitIndex = -1;
          if ((handleSplitIndex = handle.indexOf(';')) > 0) {
            const handleSplit = handle.split(';');
            const handleName = handle.substring(0, handleSplitIndex);
            for (let i = 1; i < handleSplit.length; i++) {
              let element = handleSplit[i];
              if (element.startsWith('d')) {
                element = element.substring(1);
                const number = Number.parseFloat(element);
                const scale = (3.5 / number) * 3.5;
                const move = number - 3.5;

                const handleMount = BasicUtils.findFirstChild('HandleMount', this.config.model.base);
                handleMount.getChildMeshes().forEach(mesh => {
                  mesh.scaling.z = scale;
                });
                BasicUtils.findFirstChild(handleName, this.config.model.base).position.z = -move;
              }
            }
          } else {
            const handleMount = BasicUtils.findFirstChild('HandleMount', this.config.model.base);
            handleMount.getChildMeshes().forEach(mesh => {
              mesh.scaling.z = 1;
            });
            BasicUtils.findFirstChild(handle, this.config.model.base).position.z = 0;
          }
        }
        break;
    }
  }

  public set(property: PropertyType, value: string | boolean | number, bake?: boolean) {
    this.settings.set(property, value);
    switch (property) {
      case PropertyType.Left:
      case PropertyType.Right:
        this.applySettings(ApplyType.Position);
        break;
      case PropertyType.MergeCorpusLeft:
      case PropertyType.MergeCorpusRight:
        this.applySettings(ApplyType.Merge);
        break;
      case PropertyType.BottomHeight:
        this.applySettings(ApplyType.Height);
        break;
      case PropertyType.Width:
        this.applySettings(ApplyType.Width);
        break;
      case PropertyType.Depth:
        this.applySettings(ApplyType.Depth);
        break;

      case PropertyType.Handle:
        if (value) {
          this.applySettings(ApplyType.Handle);
        }
        break;

      case PropertyType.Mark:
        if (this.config.output.mark && !this.config.output.mark.isDisposed()) this.config.output.mark.setEnabled(value as boolean);
        break;
      case PropertyType.MarkFull:
        if (this.config.output.markFull && !this.config.output.markFull.isDisposed()) this.config.output.markFull.setEnabled(value as boolean);
        break;
      case PropertyType.Label:
        if (this.config.output.label && !this.config.output.label.isDisposed()) this.config.output.label.setEnabled(value as boolean);
        break;

      case PropertyType.Rotate:
        this.applySettings(ApplyType.Rotate);
        break;

      default:
        break;
    }

    if (bake) this.bake();
  }

  public get(property: PropertyType): string | boolean | number {
    return this.settings.get(property);
  }

  public getDeviceId() {
    return this.deviceId;
  }

  public setPosition(position: { x?: number; y?: number; z?: number }) {
    // console.log('setPosition', position)
    if (typeof position.x !== 'undefined') {
      this.position.x = position.x;
    }
    if (typeof position.y !== 'undefined') {
      this.position.y = position.y;
    }
    if (typeof position.z !== 'undefined') {
      this.position.z = position.z;
    }
  }

  public getBounds() {
    return BasicUtils.getBounds(this.config.output.node);
  }

  public bake(): void {
    // console.log('bake()', this);
    HighPerformanceQueue.push(() => {
      // Clear old output
      {
        const children = this.config.output.node.getChildren();
        for (let i = 0; i < children.length; i++) {
          const child = children[i];
          if (child instanceof BABYLON.AbstractMesh) {
            for (let i = 0; i < DeviceNode.defaultSettings.shadowGenerators.length; i++) {
              const shadowGenerator = DeviceNode.defaultSettings.shadowGenerators[i];
              shadowGenerator.removeShadowCaster(child);
            }
          }
          child.dispose();
        }
      }
      // Build new Merge
      const width = this.settings.get(PropertyType.Width) as number;
      const depth = this.settings.get(PropertyType.Depth) as number;
      let left = this.settings.get(PropertyType.Left) as boolean;
      let right = this.settings.get(PropertyType.Right) as boolean;
      const mergeCorpusLeft = this.settings.get(PropertyType.MergeCorpusLeft);
      const mergeCorpusRight = this.settings.get(PropertyType.MergeCorpusRight);
      let borderLeft = 'BorderTrench';
      let borderRight = 'BorderTrench';
      let single = true;
      let singleType = null;
      let sideCover = false;
      let extended = false;
      {
        borderLeft = this.settings.get(PropertyType.Border) as string;
        borderRight = this.settings.get(PropertyType.Border) as string;
      }
      let handle = this.settings.get(PropertyType.Handle);
      let handleSplitIndex = -1;
      if (handle && (handleSplitIndex = handle.toString().indexOf(';')) > 0) {
        handle = handle.toString().substring(0, handleSplitIndex);
      }
      const bottom = this.settings.get(PropertyType.Bottom);

      if (this.config.model.base) {
        if (this.config.model.device && this.config.model.device.model.buildBaseCorpus) {
          const leftExtend = left && (borderLeft === 'BorderAngular' || borderLeft === 'BorderRound');
          const rightExtend = right && (borderRight === 'BorderAngular' || borderRight === 'BorderRound');
          if ((leftExtend || rightExtend) && single && singleType === 'Wall') {
            // +~5 cm
            const w = width / 10;
            const scaling = w / 10 + (leftExtend ? 0.4 : 0) + (rightExtend ? 0.4 : 0);
            const node = BasicUtils.findFirstChild('UpturnBack', this.config.model.base);
            const leftNode = BasicUtils.findFirstChild('Left', node);
            leftNode.position.x = leftExtend ? -4 : 0;
            const rightNode = BasicUtils.findFirstChild('Right', node);
            rightNode.position.x = w + (rightExtend ? 4 : 0);
            const centerNode = BasicUtils.findFirstChild('Center', node);
            centerNode.scaling.x = Math.max(0, scaling);
            centerNode.position.x = leftNode.position.x;
          } else {
            // +0 cm
            const w = width / 10;
            const scaling = w / 10;
            const node = BasicUtils.findFirstChild('UpturnBack', this.config.model.base);
            const leftNode = BasicUtils.findFirstChild('Left', node);
            leftNode.position.x = 0;
            const rightNode = BasicUtils.findFirstChild('Right', node);
            rightNode.position.x = w;
            const centerNode = BasicUtils.findFirstChild('Center', node);
            centerNode.scaling.x = Math.max(0, scaling);
            centerNode.position.x = leftNode.position.x;
          }

          if (handle) {
            const handleLeft = BasicUtils.findFirstChild(handle + ':Left', this.config.model.base);
            if (handleLeft != null) {
              if (leftExtend) handleLeft.position.x = -4;
              else handleLeft.position.x = 0;
            }
            const handleRight = BasicUtils.findFirstChild(handle + ':Right', this.config.model.base);
            if (handleRight != null) {
              if (rightExtend) handleRight.position.x = 4 + width / 10;
              else handleRight.position.x = width / 10;
            }
          }
        }
      }

      this.applySettings(ApplyType.Depth);

      // Fix Merge Bug...
      this._computeAllWorldMatrix();

      const nodes = new Array<BABYLON.TransformNode>();
      if (this.config.model.base) {
        if (this.config.model.device && this.config.model.device.model.buildBaseCorpus) {
          // Top
          // Corpus
          nodes.push(BasicUtils.findFirstChild('Corpus:Center', this.config.model.base));
          if (!mergeCorpusLeft) nodes.push(BasicUtils.findFirstChild('Corpus:Left', this.config.model.base));
          if (!mergeCorpusRight) nodes.push(BasicUtils.findFirstChild('Corpus:Right', this.config.model.base));
          // CorpusSideCover
          if (sideCover) {
            if (left) nodes.push(BasicUtils.findFirstChild('CorpusSideCover:Left', this.config.model.base));
            if (right) nodes.push(BasicUtils.findFirstChild('CorpusSideCover:Right', this.config.model.base));
          }
          // Panel
          if (!this.containsOption('d2nB2')) {
            nodes.push(BasicUtils.findFirstChild('Panel:Center', this.config.model.base));
            if (left) nodes.push(BasicUtils.findFirstChild('Panel:Left', this.config.model.base));
            if (right) nodes.push(BasicUtils.findFirstChild('Panel:Right', this.config.model.base));
          }
          // Panel Back
          if (single && singleType === 'Free') {
            nodes.push(BasicUtils.findFirstChild('PanelBack:Center', this.config.model.base));
            if (left) nodes.push(BasicUtils.findFirstChild('PanelBack:Left', this.config.model.base));
            if (right) nodes.push(BasicUtils.findFirstChild('PanelBack:Right', this.config.model.base));
          }
          // Border
          if (single && singleType === 'Free') {
            nodes.push(BasicUtils.findFirstChild(borderLeft + ':Center', this.config.model.base));
            if (left) nodes.push(BasicUtils.findFirstChild(borderLeft + ':Left', this.config.model.base));
            if (right) nodes.push(BasicUtils.findFirstChild(borderRight + ':Right', this.config.model.base));
          } else {
            nodes.push(BasicUtils.findFirstChild(borderLeft + ':Center:Flex', this.config.model.base));
            if (left) {
              nodes.push(BasicUtils.findFirstChild(borderLeft + ':Left:Flex', this.config.model.base));
              nodes.push(BasicUtils.findFirstChild(borderLeft + ':Left:Extension', this.config.model.base));
            }
            if (right) {
              nodes.push(BasicUtils.findFirstChild(borderRight + ':Right:Flex', this.config.model.base));
              nodes.push(BasicUtils.findFirstChild(borderRight + ':Right:Extension', this.config.model.base));
            }
          }
          // Handle
          if (handle) {
            nodes.push(BasicUtils.findFirstChild(handle + ':Center', this.config.model.base));
            const handleLeft = this.settings.get(PropertyType.HandleLeft);
            const handleRight = this.settings.get(PropertyType.HandleRight);
            if (handleLeft) {
              nodes.push(BasicUtils.findFirstChild(handle + ':Left', this.config.model.base));
              nodes.push(BasicUtils.findFirstChild('HandleMount:Left', this.config.model.base));
            }
            if (handleRight) {
              nodes.push(BasicUtils.findFirstChild(handle + ':Right', this.config.model.base));
              nodes.push(BasicUtils.findFirstChild('HandleMount:Right', this.config.model.base));
            }
          }
          // UpturnBack
          if (single && singleType === 'Wall') {
            nodes.push(BasicUtils.findFirstChild('UpturnBack:Center', this.config.model.base));
            if (left) nodes.push(BasicUtils.findFirstChild('UpturnBack:Left', this.config.model.base));
            if (right) nodes.push(BasicUtils.findFirstChild('UpturnBack:Right', this.config.model.base));
          }
        }
        // Bottom
        // PedestrialCover
        if (bottom === 'Feet') {
          const feet = BasicUtils.findFirstChild('Feet', this.config.model.base);
          if (mergeCorpusLeft || mergeCorpusRight) {
            if (!mergeCorpusLeft) nodes.push(BasicUtils.findFirstChild('Left', feet));
            if (!mergeCorpusRight) nodes.push(BasicUtils.findFirstChild('Right', feet));
          } else {
            nodes.push(feet);
          }
        } else {
          nodes.push(BasicUtils.findFirstChild('PedestrialCover:Center', this.config.model.base));
          if (left) nodes.push(BasicUtils.findFirstChild('PedestrialCover:Left', this.config.model.base));
          if (right) nodes.push(BasicUtils.findFirstChild('PedestrialCover:Right', this.config.model.base));
        }
      }

      // Add main
      if (this.config.model.main) {
        let splittedPart: BABYLON.TransformNode = null;
        if (!this._mainSplit) nodes.push(this.config.model.main);
        else if (depth === 700 && (splittedPart = BasicUtils.findFirstChild('700', this.config.model.main))) nodes.push(splittedPart);
        else if (depth === 850 && (splittedPart = BasicUtils.findFirstChild('850', this.config.model.main))) nodes.push(splittedPart);
        if (depth === 850 && this.config.model.device && this.config.model.device.model.extendCover) {
          // Cover
          nodes.push(BasicUtils.findFirstChild('CoverSplit', this.config.model.base));
        }
      } else if (this.config.model.base) {
        // Cover
        nodes.push(BasicUtils.findFirstChild('Cover', this.config.model.base));
      }

      // Add all options
      const options = this.config.model.options.values();
      let option = null;
      while (typeof (option = options.next().value) !== 'undefined') {
        // MKN Badge
        if (mergeCorpusRight && option.getDeviceComponent().component.id === 'VKx8K') continue;
        if (!right && option.getDeviceComponent().component.id === 'VKx8K') continue;
        // FrontCover
        if ((mergeCorpusRight || mergeCorpusLeft) && option.getDeviceComponent().component.id === 'aL3z4') continue;
        const meshes = option.getChildMeshes();
        for (let j = 0; j < meshes.length; j++) {
          const mesh = meshes[j];
          nodes.push(mesh);
        }
      }

      // Merge
      const materialMap = new Map<string, BABYLON.Mesh[]>();
      const materialMapKeys = new Array<string>();
      for (let i = 0; i < nodes.length; i++) {
        const element = nodes[i];
        const childMeshes = element.getChildMeshes();
        for (let i = 0; i < childMeshes.length; i++) {
          const mesh = childMeshes[i];
          // console.log('check', mesh.name, mesh.material ? mesh.material.name : '', mesh);
          if (mesh instanceof BABYLON.Mesh && mesh.material) {
            switch (this.settings.get(PropertyType.Depth) as number) {
              case 700:
                if (mesh.parent.name === 'Extension') {
                  continue;
                }
                break;
              case 850:
                break;
            }
            if (single && mesh.parent.name === 'Fill') continue;
            if (materialMap.has(mesh.material.name)) {
              materialMap.get(mesh.material.name).push(mesh);
            } else {
              materialMap.set(mesh.material.name, [mesh]);
              materialMapKeys.push(mesh.material.name);
            }
          }
        }
      }
      let metal: BABYLON.Mesh = null;
      let eraser: BABYLON.Mesh = null;
      for (let i = 0; i < materialMapKeys.length; i++) {
        // console.log('.....................................');
        const mat = materialMapKeys[i];
        const meshes = materialMap.get(mat);
        // console.log('merge', mat, meshes);
        // const mergedMesh = BABYLON.Mesh.MergeMeshes(meshes, false, true);
        const mergedMesh = BABYLON.Mesh.MergeMeshes(meshes, false, true, undefined, false);
        mergedMesh.name = 'mesh-' + mat;
        mergedMesh.parent = this.config.output.node;
        for (let i = 0; i < DeviceNode.defaultSettings.shadowGenerators.length; i++) {
          const shadowGenerator = DeviceNode.defaultSettings.shadowGenerators[i];
          shadowGenerator.addShadowCaster(mergedMesh);
        }
        for (let i = 0; i < DeviceNode.defaultSettings.mirrors.length; i++) {
          const mirror = DeviceNode.defaultSettings.mirrors[i];
          mirror.renderList.push(mergedMesh);
        }
        mergedMesh.receiveShadows = true;
        if (mergedMesh.name === 'mesh-_metal') {
          metal = mergedMesh;
        } else if (mergedMesh.name === 'mesh-eraser') {
          eraser = mergedMesh;
        }
      }
      // Erase from Metal
      if (metal && eraser) {
        const csgMesh = BABYLON.CSG.FromMesh(metal).inverse().subtract(BABYLON.CSG.FromMesh(eraser)).inverse();
        const newMesh = csgMesh.toMesh(metal.name + '-erased', metal.material, metal.getScene(), true);

        newMesh.parent = this.config.output.node;
        for (let i = 0; i < DeviceNode.defaultSettings.shadowGenerators.length; i++) {
          const shadowGenerator = DeviceNode.defaultSettings.shadowGenerators[i];
          shadowGenerator.addShadowCaster(newMesh);
        }
        for (let i = 0; i < DeviceNode.defaultSettings.mirrors.length; i++) {
          const mirror = DeviceNode.defaultSettings.mirrors[i];
          mirror.renderList.push(newMesh);
        }
        newMesh.receiveShadows = true;

        metal.dispose();
        eraser.dispose();
      }

      const bounds = this.getBounds();
      // console.log('Merged Bounds', bounds);
      // Add Marks
      this.config.output.mark = MarkUtils.buildMark({
        width: bounds.width,
        height: bounds.height,
        depth: bounds.depth,
        offset: new BABYLON.Vector3(bounds.x.min, bounds.y.min, bounds.z.max),
        parent: this.config.output.node
      });
      this.config.output.mark.setEnabled(this.get(PropertyType.Mark) as boolean);
      this.config.output.markFull = MarkUtils.buildFullMark({
        width: bounds.width,
        height: bounds.height,
        depth: bounds.depth,
        offset: new BABYLON.Vector3(bounds.x.min, bounds.y.min, bounds.z.max),
        parent: this.config.output.node
      });
      this.config.output.markFull.setEnabled(this.get(PropertyType.MarkFull) as boolean);
      // Add Label
      this.config.output.label = LabelUtils.drawLabel('' + width, width / 10, Orientation.Bottom);
      if (this.get(PropertyType.Rotate)) BasicUtils.findFirstChild('text', this.config.output.label).rotation.y = Math.PI;
      this.config.output.label.parent = this.config.output.node;
      this.config.output.label.position.z = -depth / 10;
      this.config.output.label.setEnabled(this.get(PropertyType.Label) as boolean);

      this.extended(extended);

      return true;
    });
  }

  public extended(extended: boolean) {
    if (extended) {
      const depth = this.settings.get(PropertyType.Depth) as number;
      const scale = (depth + 50) / depth;
      this.config.output.node.scaling.z = scale;
    } else {
      this.config.output.node.scaling.z = 1;
    }
  }

  private _computeAllWorldMatrix() {
    const childs = this.config.model.node.getChildTransformNodes();
    for (let i = 0; i < childs.length; i++) {
      const child = childs[i];
      child.computeWorldMatrix();
    }
  }

  dispose() {
    this.config.model.node.dispose();
    super.dispose();
  }
}
