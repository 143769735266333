import * as BABYLON from "babylonjs";
import BasicUtils from "../util/BasicUtils";
import DeviceNode, { PropertyType } from "./DeviceNode";
import { DeviceComponent, Device } from "types/Device";
import { Mesh } from "babylonjs";

export class OptionNode extends BABYLON.TransformNode {
  private _container: DeviceNode;
  private _deviceComponent: DeviceComponent;
  private _model: BABYLON.TransformNode;

  constructor(node: BABYLON.TransformNode, deviceComponent: DeviceComponent) {
    super("option." + deviceComponent.component.id, node.getScene(), undefined);
    this._model = node;
    this._model.parent = this;
    this._deviceComponent = deviceComponent;
  }

  public prepareType() {
    if (this._deviceComponent && this._container) {
      const targetWidth = this._container.get(PropertyType.Width) as number;
      // mm to cm
      const width = targetWidth / 10;
      // cm to scaling
      const scaling = width / 10;

      switch (this._deviceComponent.component.id) {
        // WingedDoor
        case "7KP0K":
          {
            if (targetWidth > 600) {
              const halfWidth = width / 2;
              const halfScaling = scaling / 2;
              const doorLeft = BasicUtils.findFirstChild("Door", this._model);
              const doorRight = BasicUtils.clone(doorLeft, "DoorRight");
              // Left
              {
                BasicUtils.findFirstChild(
                  "Center",
                  doorLeft
                ).scaling.x = Math.max(0, halfScaling - 2);
                const right = BasicUtils.findFirstChild("Right", doorLeft);
                right.position.x = halfWidth - 20;
              }
              // Right
              {
                BasicUtils.findFirstChild(
                  "Center",
                  doorRight
                ).scaling.x = Math.max(0, halfScaling - 2);
                const right = BasicUtils.findFirstChild("Right", doorRight);
                right.position.x = halfWidth - 20;
                doorRight.scaling.x = -1;
                doorRight.position.x = width;
              }
            } else {
              BasicUtils.findFirstChild(
                "Center",
                this._model
              ).scaling.x = Math.max(0, scaling - 2);
              const right = BasicUtils.findFirstChild("Right", this._model);
              right.position.x = width - 20;
            }
          }
          break;
        // UpperDrawer
        case "d2nB2":
          {
            BasicUtils.findFirstChild(
              "Center",
              this._model
            ).scaling.x = Math.max(0, scaling - 2);
            const left = BasicUtils.findFirstChild("Left", this._model);
            const right = BasicUtils.clone(left, "Right");
            right.scaling.x = -1;
            right.position.x = width;
          }
          break;
        // Drawer 1x & 2x
        case "WKOJL":
        case "R2QoL":
          {
            BasicUtils.findFirstChild(
              "Center",
              this._model
            ).scaling.x = Math.max(0, scaling - 2);
            const right = BasicUtils.findFirstChild("Right", this._model);
            right.position.x = width - 20;
          }
          break;
        // Hygiene
        case "OK7l2":
          {
            //const node = BasicUtils.findFirstChild('Hygiene', this._model);
            BasicUtils.findFirstChild(
              "Center",
              this._model
            ).scaling.x = Math.max(0, scaling);
            const left = BasicUtils.findFirstChild("Left", this._model);
            const right = BasicUtils.clone(left, "Right");
            right.position.x = width;
            right.scaling.x = -1;
            this.prepareTypeDepthChange();
          }
          break;
        // AngularRail
        case "wKBAL":
          {
            const node = BasicUtils.findFirstChild("URail", this._model);
            const left = BasicUtils.findFirstChild("Left", node);
            const right = BasicUtils.clone(left, "Right");
            right.position.x = width;
            right.scaling.x = -1;
            this.prepareTypeDepthChange();
          }
          break;
        // UpperPowerSocket
        case "3Kw7K":
        // MixingFaucet
        case "g28wK":
          {
            this._model.position.x = width / 2;
          }
          break;
        // EOven
        case "GLzd2":
        // GOven
        case "eKRML":
        // GOvenExhaust
        case "32bxL":
          if (targetWidth > 800) {
            this._model.position.x = width - 80;
          }
          break;
        // Emptying System
        case "E4mx2":
          const esw = 26.848;
          this._model.scaling.x = (width - 5) / esw;
          break;
        // UpturnBack
        case '28OwK':
          {
            BasicUtils.findFirstChild('Center', this._model).scaling.x = Math.max(0, scaling - 2);
            const right = BasicUtils.findFirstChild('Right', this._model);
            right.position.x = Math.max(0, width - 20);
          }
          break;
        // Flexi
        case 'g4XmL':
        case 'k2VxL':
        case 'dKpgL':
          if(this.getDeviceComponent().scaling.x == -1) {
            const mesh = (BasicUtils.findFirstChild('display', this) as Mesh);
            mesh.material = this.getScene().getMaterialByName(mesh.material.name + '_m');
          }
          break;
      }
    }
  }

  public prepareTypeDepthChange() {
    if (this._deviceComponent && this._container) {
      switch (this._deviceComponent.component.id) {
        // Hygiene
        case "OK7l2":
        // AngularRail
        case "wKBAL":
          {
            const depth = this._container.get(PropertyType.Depth) as number;
            const left = BasicUtils.findFirstChild("Left", this._model);
            const right = BasicUtils.findFirstChild("Right", this._model);
            if (depth === 700) {
              BasicUtils.findFirstChild("Flex", left).position.z = 15;
              BasicUtils.findFirstChild("Flex", right).position.z = 15;
            } else {
              BasicUtils.findFirstChild("Flex", left).position.z = 0;
              BasicUtils.findFirstChild("Flex", right).position.z = 0;
            }
          }
          break;
      }
    }
  }

  public getDeviceComponent() {
    return this._deviceComponent;
  }

  public getModel() {
    return this._model;
  }

  public setContainer(container: DeviceNode) {
    this._container = container;
  }
}
