export default class HighPerformanceQueue {
  private static queue = new Array<() => boolean>();
  private static isRunning = false;

  public static push(f: () => boolean) {
    HighPerformanceQueue.queue.push(f);
    HighPerformanceQueue.call();
  }

  private static call() {
    const f = HighPerformanceQueue.queue.shift();
    if (!HighPerformanceQueue.isRunning && f) {
      HighPerformanceQueue.isRunning = true;
      try {
        f();
      } catch (e) {
        console.error(e);
      }
      setTimeout(HighPerformanceQueue.call, 100);
      HighPerformanceQueue.isRunning = false;
    }
  }
}
