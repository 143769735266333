import * as BABYLON from 'babylonjs'
import 'babylonjs-loaders'
import BasicUtils, { Bounds } from './BasicUtils'
import DeviceNode from '../node/DeviceNode'
import axios from 'axios'
import { DeviceComponent } from 'types/Device'
import { OptionNode } from '../node/OptionNode'

export type ImportSettings = {
  scaling?: BABYLON.Vector3
  rotation?: BABYLON.Vector3
  scene?: BABYLON.Scene
  shadowGenerators?: BABYLON.ShadowGenerator[]
  mirrors?: BABYLON.AbstractMesh[]
  pluginExtension?: string
}

export type LoadReturn = {
  bounds: Bounds
  model: BABYLON.TransformNode
}

export default class LoaderUtils {
  private static defaultSettings: ImportSettings = {
    scaling: new BABYLON.Vector3(1, 1, 1),
    rotation: new BABYLON.Vector3(0, 0, 0),
    scene: null,
    // shadowGenerators: null,
    // mirrors: null,
    pluginExtension: '.gltf',
  }

  public static initialize(scene: BABYLON.Scene): void {
    LoaderUtils.defaultSettings.scene = scene

    if (LoaderUtils.loadedNode && !LoaderUtils.loadedNode.isDisposed())
      LoaderUtils.loadedNode.dispose()
    LoaderUtils.loadedNode = new BABYLON.TransformNode('_loader', scene)
    LoaderUtils.loadedNode.setEnabled(false)

    LoaderUtils.loading.clear()
    LoaderUtils.loaded.clear()
  }

  private static loading = new Map<string, Array<(r: LoadReturn) => void>>()
  private static loaded = new Map<string, BABYLON.TransformNode>()
  private static loadedNode: BABYLON.TransformNode = null

  public static load(obj: string, callback?: (r: LoadReturn) => void, error?: () => void): void {
    // Check if model has already been loaded
    if (LoaderUtils.loaded.has(obj)) {
      const model = LoaderUtils.loaded.get(obj)
      const r = {
        bounds: BasicUtils.getBounds(model),
        model: BasicUtils.clone(model),
      }
      if (callback) callback(r)
    }
    // Check if model is loading
    else if (LoaderUtils.loading.has(obj)) {
      LoaderUtils.loading.get(obj).push(callback)
    }
    // Load model
    else {
      LoaderUtils.loading.set(obj, [])
      const scaling = LoaderUtils.defaultSettings.scaling
      const rotation = LoaderUtils.defaultSettings.rotation
      const scene = LoaderUtils.defaultSettings.scene
      // const shadowGenerators = LoaderUtils.defaultSettings.shadowGenerators;
      // const mirrors = LoaderUtils.defaultSettings.mirrors;
      const pluginExtension = LoaderUtils.defaultSettings.pluginExtension

      var model = new BABYLON.TransformNode(obj, scene)

      console.log('load', obj)

      axios
        .get(obj, {
          withCredentials: true,
          headers: {
            jwt: window.localStorage.getItem('jwt-mkn-admin'),
          },
        })
        .then((response) => {
          const text = JSON.stringify(response.data)
          const materialsToDispose = new Map<number, BABYLON.Material>()
          BABYLON.SceneLoader.ImportMesh(
            '',
            '',
            'data:' + text,
            scene,
            (_meshes, _particleSystems, _skeletons, _animationGroups) => {
              // console.log('newMeshes', _meshes)
              for (var i = 0; i < _meshes.length; i++) {
                const m = _meshes[i]
                if (i < 1) {
                  m.parent = model
                  m.position = BABYLON.Vector3.Zero()
                  m.scaling = scaling
                  m.rotation = rotation
                }

                m.cullingStrategy = BABYLON.Mesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY

                // remove duplicated materials
                if (m.material) {
                  m.material.backFaceCulling = false
                  for (let i = 0; i < scene.materials.length; i++) {
                    const material = scene.materials[i]
                    if (
                      material.name === '_' + m.material.name &&
                      material.uniqueId !== m.material.uniqueId
                    ) {
                      materialsToDispose.set(m.material.uniqueId, m.material)
                      m.material = material
                      break
                    }
                  }
                }
              }

              materialsToDispose.forEach((_value, _key) => {
                _value.dispose()
              })

              const t = model.getChildTransformNodes()
              for (let i = 0; i < t.length; i++) {
                const e = t[i]
                e.rotation = BABYLON.Vector3.Zero()
              }

              model.parent = LoaderUtils.loadedNode

              LoaderUtils.loaded.set(obj, model)

              const r = {
                bounds: BasicUtils.getBounds(model),
                model: BasicUtils.clone(model),
              }

              console.log('Loaded ' + obj, r)

              if (callback) callback(r)

              const asyncCallbacks = LoaderUtils.loading.get(obj)
              LoaderUtils.loading.delete(obj)
              for (let i = 0; i < asyncCallbacks.length; i++) {
                const asyncCallback = asyncCallbacks[i]
                const ar = {
                  bounds: r.bounds,
                  model: BasicUtils.clone(model),
                }
                asyncCallback(ar)
              }
            },
            undefined,
            undefined,
            pluginExtension
          )
        })
        .catch((reason) => {
          console.log('Unable to load ' + obj, reason)
          model.dispose()
          if (error) error()
        })
    }
  }

  public static loadBase(
    deviceNode: DeviceNode,
    success?: (node: DeviceNode) => void,
    error?: () => void
  ): DeviceNode {
    LoaderUtils.load(
      '/gltf/generic_corpus.gltf',
      (r) => {
        deviceNode.addBase(r.model)
        if (success) success(deviceNode)
      },
      () => {
        if (error) error()
      }
    )
    return deviceNode
  }

  public static loadDevice(
    deviceNode: DeviceNode,
    success?: (node: DeviceNode) => void,
    error?: () => void
  ): DeviceNode {
    LoaderUtils.load(
      `${
        process.env.REACT_APP_API_URL
      }/device/get/${deviceNode.getDeviceId()}/model/object?_=${Date.now()}`,
      (r: LoadReturn) => {
        deviceNode.setMain(r.model)
        if (success) success(deviceNode)
      },
      () => {
        if (error) error()
      }
    )
    return deviceNode
  }

  public static loadComponent(
    deviceNode: DeviceNode,
    component: DeviceComponent,
    success?: (node: OptionNode) => void,
    error?: () => void
  ): DeviceNode {
    LoaderUtils.load(
      `${process.env.REACT_APP_API_URL}/component/get/${component.component.id}/object`,
      (r: LoadReturn) => {
        const optionNode = deviceNode.addOption(new OptionNode(r.model, component))
        if (success) success(optionNode)
      },
      () => {
        if (error) error()
      }
    )
    return deviceNode
  }
}
