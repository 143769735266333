import React, { FC, RefObject, useContext } from 'react';

// Components
import { ButtonHollow, EditProperty, UploadObject, UploadImage } from 'components/';

// Types:

// Babylon:
import PreviewBabylonScene from 'components/babylon/PreviewBabylonScene';
import { SceneEventArgs, SceneMountReturn } from 'components/babylon/BabylonScene';
import DeviceNode from 'components/babylon/node/DeviceNode';

// Utils:
import createImage from 'utils/createImage';
import { post } from 'utils/AJAX';

// Types
import { Device, Model, Type, DeviceComponent } from 'types/Device';
import authContext from '../../../context/auth-context';

interface Props {
  components: DeviceComponent[];
  device: Device;
  id: string;
  model: Model;
  previewDepth: number;
  previewObject: DeviceNode;
  sceneRef: RefObject<PreviewBabylonScene>;
  setError: (e: boolean) => void;
  setModel: (m: Model) => void;
  setPreviewObject: (p: DeviceNode) => void;
  setSuccess: (s: boolean) => void;
  type: Type;
}

const Media: FC<Props> = ({ components, device, id, model, previewDepth, previewObject, sceneRef, setError, setModel, setPreviewObject, setSuccess, type }) => {
  const { userRights } = useContext(authContext);
  const modularAllowed = Array.isArray(userRights) && userRights.includes('EditModular');
  const masterlineAllowed = Array.isArray(userRights) && userRights.includes('EditMasterline');
  const refreshPreview = (depth?: number, extendCover?: boolean, buildBaseCorpus?: boolean) => {
    if (previewObject && !previewObject.isDisposed()) {
      previewObject.dispose();
    }
    const deviceNode = new DeviceNode({
      id: id,
      width: model.width,
      left: true,
      right: true,
      depth: depth || previewDepth,
      bottom: 'Feet',
      device
    });

    deviceNode.position.x = -model.width / 20;
    deviceNode.position.z = -previewDepth / 20;

    if (sceneRef && sceneRef.current) sceneRef.current.setPreview(deviceNode);

    setPreviewObject(deviceNode);
  };

  const onSceneMounted = (e: SceneEventArgs) => {
    const sceneMountReturn: SceneMountReturn = {
      resizeEvents: new Array<() => void>()
    };
    // const { canvas, scene, engine } = e;

    refreshPreview();

    return sceneMountReturn;
  };

  const uploadImage = async (image: File) => {
    const formData = new FormData();
    formData.append('file', image);
    console.log(image);
    console.log(formData);
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/save/${id}/model/image`, { data: formData });

    if (data) {
      setSuccess(true);
      setError(false);
    } else if (error) {
      setError(true);
      setSuccess(false);
    }
  };

  return (
    <>
      <div>
        <h3 className="Device-SubCategory-Header">Media</h3>
        <p>To update the device image please</p>
        <ButtonHollow
          onClick={() => {
            if (sceneRef && sceneRef.current) {
              sceneRef.current.setCameraDiagonal();
              sceneRef.current.setPreview(previewObject);
              sceneRef.current.screenshot(data => {
                const screenshot = createImage(data, `${device.id}-image.png`);
                uploadImage(screenshot);
              });
            }
          }}
        >
          Take a screenshot!!!
        </ButtonHollow>
        <div style={{ margin: '1.5rem 0' }}></div>
        <ButtonHollow
          onClick={() => {
            if (sceneRef && sceneRef.current) {
              sceneRef.current.setCameraCombi();
              sceneRef.current.setPreview(previewObject);
              sceneRef.current.screenshot(data => {
                const screenshot = createImage(data, `${device.id}-image.png`);
                uploadImage(screenshot);
              }, 'Combi');
            }
          }}
        >
          Take a screenshot with combi cam!!!
        </ButtonHollow>
        <div style={{ margin: '1.5rem 0' }}>
          <UploadImage
            error={false}
            id={id}
            setUploadedImage={(image: File) => {
              uploadImage(image);
            }}
            success={false}
          />
        </div>
        <div style={{ margin: '1.5rem 0' }}>
          <UploadObject id={id} parentType={'device'} onUploadSuccess={onSceneMounted} />
        </div>
        <div
          className="Device-Checkboxes-Wrapper-Box"
          style={{
            marginBottom: '.5rem',
            cursor: masterlineAllowed ? 'pointer' : 'not-allowed',
            color: masterlineAllowed ? '#fff' : '#ccc'
          }}
        >
          <input
            checked={model.marineMeister}
            disabled={!masterlineAllowed}
            id="modelMarineMeister"
            onChange={e =>
              setModel({
                ...model,
                marineMeister: e.target.checked
              })
            }
            type="checkbox"
          />
          <label htmlFor="modelMarineMeister">MarineMeister</label>
        </div>
        <div
          className="Device-Checkboxes-Wrapper-Box"
          style={{
            marginBottom: '.5rem',
            cursor: masterlineAllowed ? 'pointer' : 'not-allowed',
            color: masterlineAllowed ? '#fff' : '#ccc'
          }}
        >
          <input
            checked={model.masterline}
            disabled={!masterlineAllowed}
            id="modelMasterline"
            onChange={e =>
              setModel({
                ...model,
                masterline: e.target.checked
              })
            }
            type="checkbox"
          />
          <label htmlFor="modelMasterline">Masterline</label>
        </div>
        <div
          className="Device-Checkboxes-Wrapper-Box"
          style={{
            marginBottom: '.5rem',
            cursor: modularAllowed ? 'pointer' : 'not-allowed',
            color: modularAllowed ? '#fff' : '#ccc'
          }}
        >
          <input
            checked={model.modular}
            disabled={!modularAllowed}
            id="modelModular"
            onChange={e =>
              setModel({
                ...model,
                modular: e.target.checked
              })
            }
            type="checkbox"
          />
          <label htmlFor="modelModular">Modular</label>
        </div>
        <div
          className="Device-Checkboxes-Wrapper-Box"
          style={{
            marginBottom: '.5rem',
            marginLeft: '1rem',
            cursor: model.modular ? 'pointer' : 'not-allowed',
            color: model.modular ? '#fff' : '#ccc'
          }}
        >
          <input
            checked={model.modularBorderIncluded}
            disabled={!model.modular}
            id="modularOnly"
            onChange={e =>
              setModel({
                ...model,
                modularBorderIncluded: e.target.checked
              })
            }
            style={{ cursor: model.modular ? 'pointer' : 'not-allowed' }}
            type="checkbox"
          />
          <label htmlFor="modularOnly">Modular Border Included</label>
        </div>
        <div
          className="Device-Checkboxes-Wrapper-Box"
          style={{
            marginBottom: '.5rem',
            cursor: masterlineAllowed ? 'pointer' : 'not-allowed',
            color: masterlineAllowed ? '#fff' : '#ccc'
          }}
        >
          <input
            checked={model.flexiChef}
            disabled={!modularAllowed}
            id="modelFlexichef"
            onChange={e =>
              setModel({
                ...model,
                flexiChef: e.target.checked
              })
            }
            type="checkbox"
          />
          <label htmlFor="modelFlexichef">Flexichef</label>
        </div>
        <div
          className="Device-Checkboxes-Wrapper-Box"
          style={{
            marginBottom: '.5rem',
            cursor: masterlineAllowed ? 'pointer' : 'not-allowed',
            color: masterlineAllowed ? '#fff' : '#ccc'
          }}
        >
          <input
            checked={model.spaceCombi}
            disabled={!modularAllowed}
            id="modelSpaceCombi"
            onChange={e =>
              setModel({
                ...model,
                spaceCombi: e.target.checked
              })
            }
            type="checkbox"
          />
          <label htmlFor="modelSpaceCombi">Combi Steamer</label>
        </div>
        <div className="Device-Checkboxes-Wrapper-Box" style={{ marginBottom: '.5rem' }}>
          <input
            checked={model.buildBaseCorpus}
            id="modelBuildBaseCorpus"
            onChange={e => {
              setModel({
                ...model,
                buildBaseCorpus: e.target.checked
              });
              refreshPreview(undefined, undefined, e.target.checked);
            }}
            type="checkbox"
          />
          <label htmlFor="modelBuildBaseCorpus">Build Base Corpus</label>
        </div>
        <div className="Device-Checkboxes-Wrapper-Box" style={{ marginBottom: '.5rem' }}>
          <input
            checked={model.extendCover}
            id="modelextendable"
            onChange={e => {
              setModel({
                ...model,
                extendCover: e.target.checked,
                extendCoverMoveBack: false
              });
              refreshPreview(undefined, e.target.checked);
            }}
            type="checkbox"
          />
          <label htmlFor="modelextendable">Extend Cover</label>
        </div>
        <EditProperty description="Extend Cover Size:">
          <input
            className="EditProperty-Input-Textfield"
            onChange={event => {
              setModel({
                ...model,
                extendCoverSize: parseInt(event.target.value)
              });
            }}
            min={0}
            max={150}
            type="number"
            value={model.extendCoverSize}
          />
        </EditProperty>
        {/* <div className="Device-Checkboxes-Wrapper-Box">
          <input
            checked={model.extendCoverMoveBack}
            disabled={!model.extendCover}
            id="modelextendableback"
            onChange={e => {
              setModel({
                ...model,
                extendCoverMoveBack: e.target.checked
              });
              refreshPreview(undefined, e.target.checked);
            }}
            style={{ cursor: model.extendCover ? 'pointer' : 'not-allowed' }}
            type="checkbox"
          />
          <label
            htmlFor="modelextendableback"
            style={{ cursor: model.extendCover ? 'pointer' : 'not-allowed' }}
          >
            Extend Cover Move Back
          </label>
        </div> */}
      </div>
    </>
  );
};

export default Media;
